import { css } from '@emotion/css';
import { colorHelpers, colors, styleHelpers } from '@imtbl/design-system';

export const HeroContainer = css`
  position: relative;
  width: 100%;
`;

export const InnerContainer = css`
  position: relative;
`;

export const GradientSkrim = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    ${colors.bg[900]} 20%,
    rgba(${colorHelpers.hexToCssRgbString(colors.bg[900])}, 0) 100%
  );
`;

export const CollectionItem = css`
  width: 100%;
  display: none;

  &:first-child {
    display: block;
  }
`;

export const CollectionItem___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'small',
    `display: block; width: 33%;`,
  );

export const BackgroundImageWrapper = css`
  display: none;
`;

export const BackgroundImageWrapper___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery('small', `display: block;`);

export const SearchControlContainer = css`
  width: 100%;
  max-width: 600px;
`;

export const SearchControlContainer_searchControl =
  styleHelpers.renderEmotionStylesWithMediaQuery('small', `width: 100%`);

export const Heading = css`
  width: 100%;
`;

export const Heading_sText = css`
  display: none;
`;

export const Heading_sText___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery('small', `display: inline`);
