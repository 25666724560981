import { css } from '@emotion/css';
import {
  colors,
  measurements,
  OuterSpace,
  SimpleText,
} from '@imtbl/design-system';

import { useURLSearchParams } from '../../hooks/useURLSearchParams.hook';
import { useCollections } from '../../marketplace-api/collections';
import { InfiniteCollectionsGrid } from '../CollectionsListingPage/shared/InfiniteCollectionsGrid.component';
import { NoAssets } from '../NoAssets';
import * as styles from './Homepage.styles';

export function CollectionsSearchResults() {
  const { collections } = useCollections();
  const searchParams = useURLSearchParams();
  const keywordSearch = searchParams.getKeywordSearch();
  const hasResults = Boolean(collections?.length);

  return (
    <>
      <OuterSpace
        top={measurements.SpacingTeeShirtAmounts.large}
        bottom={measurements.SpacingTeeShirtAmounts['2x-large']}
        responsiveSpacing={[
          {
            breakpoint: 'small',
            bottom: measurements.SpacingTeeShirtAmounts['4x-large'],
          },
        ]}
      >
        <SimpleText
          fillColor={colors.white}
          testId="searchResultsCopy"
          className={styles.SearchControlContainer}
        >
          Collection results for <strong>&quot;{keywordSearch}&quot;</strong>
        </SimpleText>
      </OuterSpace>
      {hasResults ? (
        <InfiniteCollectionsGrid
          testId="searchCollectionsInfiniteListing"
          className={css`
            width: 100%;
          `}
        />
      ) : (
        <NoAssets testId="noCollectionsMessaging" />
      )}
    </>
  );
}
