import { css } from '@emotion/css';
import {
  Box,
  breakpoints,
  colors,
  CustomUiText,
  FlexLayout,
  layoutHelpers,
  OuterSpace,
  ParagraphText,
  StandardButton,
} from '@imtbl/design-system';

import {
  ButtonEventName,
  createButtonEvent,
  sendAnalytics,
} from '../../../libs/analytics';

export const TokenGiveAwayBanner = ({
  testId = 'tokenGiveAwayPromoBanner',
}: {
  testId?: string;
}) => (
  <FlexLayout
    flexDirection="column"
    width="100%"
    backgroundColor={colors.bg[900]}
    justifyContent="center"
    testId={testId}
    className={css`
      position: relative;
    `}
    paddingTop={layoutHelpers.gridUnits(4.5)}
    paddingBottom={layoutHelpers.gridUnits(5.5)}
  >
    <FlexLayout
      maxWidth={breakpoints.breakpointSizeMapping.large}
      marginLeft="auto"
      marginRight="auto"
      paddingLeft={layoutHelpers.gridUnits(3)}
      paddingRight={layoutHelpers.gridUnits(3)}
      width="100%"
      flexDirection="column"
      responsiveProperties={[
        {
          breakpoint: 'small',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        {
          breakpoint: 'medium',
          paddingLeft: layoutHelpers.gridUnits(6),
          paddingRight: layoutHelpers.gridUnits(6),
        },
        {
          breakpoint: 'large',
          paddingLeft: layoutHelpers.gridUnits(3),
          paddingRight: layoutHelpers.gridUnits(3),
        },
      ]}
    >
      <FlexLayout flexDirection="column">
        <CustomUiText
          fontSize="small"
          fillColor={colors.light[100]}
          fontWeight="bold"
        >
          You&apos;re eligible for the Trading Rewards program!
        </CustomUiText>
        <OuterSpace top={layoutHelpers.gridUnits(1)}>
          <ParagraphText fillColor={colors.light[100]} fontSize="small">
            Earn $IMX with every trade. For a limited time only.
          </ParagraphText>
        </OuterSpace>
      </FlexLayout>

      <OuterSpace
        top={layoutHelpers.gridUnits(5)}
        responsiveSpacing={[{ breakpoint: 'small', top: '0px' }]}
      >
        <StandardButton
          href="https://www.immutable.com/trade-to-earn"
          target="_blank"
          disabled={false}
          buttonKind="primary"
          testId="learnMoreCtaButton"
          onClick={() =>
            sendAnalytics(
              createButtonEvent(
                ButtonEventName.globalNavTradeAndEarnBtnPressed,
              ),
            )
          }
        >
          Learn More
        </StandardButton>
      </OuterSpace>
    </FlexLayout>
    <Box
      className={css`
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: ${layoutHelpers.gridUnits(0.5)};

        /* @NOTE: custom colors needed here, because the DS has not yet been
          refactored to support the new brand colors. 🥲 */
        background: radial-gradient(
          73.48% 242.97% at 26.54% 26.42%,
          rgba(118, 236, 248, 0.6) 0%,
          #a6aeec 38.02%,
          #ecbee1 100%
        );
      `}
    />
  </FlexLayout>
);
