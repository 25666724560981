import { FlexLayout } from '@imtbl/design-system';
import { API_URL } from 'api/constants';
import { CollectionsPageMainContent } from 'components/CollectionsListingPage/CollectionsPageMainContent';
import { FeatureFlag } from 'components/FeatureFlag';
import { Homepage } from 'components/Homepage/Homepage.component';
import { PageSeo } from 'components/PageSeo';
import { FEATURE_FLAG } from 'config/feature-flags';
import { HOME_PAGE } from 'config/pages';
import { fetchCollections } from 'marketplace-api/collections';
import { MarketplaceApiCollection } from 'marketplace-api/types';
import { GetServerSideProps } from 'next';

import {
  randomlyChooseCollections,
  randomNumberFromBetweenXandY,
} from '../utils/collections';
import { serverLaunchDarklyFlagsInstance } from './_app';

export interface CollectionsPagePropTypes {
  featuredCollections: MarketplaceApiCollection[];
  randomImageIndex: number;
}

export default function CollectionsPage({
  featuredCollections,
  randomImageIndex,
}: CollectionsPagePropTypes) {
  return (
    <>
      <PageSeo
        title={HOME_PAGE.title}
        description={HOME_PAGE.description}
        twitterTitle={HOME_PAGE.twitterTitle}
        twitterDescription={HOME_PAGE.twitterDescription}
      />
      <FlexLayout testId="pageContainer" flexGrow={1}>
        <FeatureFlag
          name={FEATURE_FLAG.ENABLE_NEW_HOMEPAGE}
          fallback={
            <CollectionsPageMainContent
              featuredCollections={featuredCollections}
            />
          }
        >
          <Homepage
            featuredCollections={featuredCollections}
            randomImageIndex={randomImageIndex}
          />
        </FeatureFlag>
      </FlexLayout>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ res }) => {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=60, stale-while-revalidate=300',
  );
  const { result: featuredCollections } = await fetchCollections(
    {
      whiteList:
        serverLaunchDarklyFlagsInstance?.imx4079_featuredCollectionAddresses,
      // @NOTE: we have to use the public API's for this call, just because the new marketplace-api
      // does NOT support the whiteList param / field
      apiHost: API_URL,
    },
    process.env.IMX_API_KEY,
  );

  const randomGroupOf3Collections = randomlyChooseCollections(
    3,
    featuredCollections,
  );

  return {
    props: {
      featuredCollections: serverLaunchDarklyFlagsInstance[
        'mp1233_enable-redesigned-homepage'
      ]
        ? randomGroupOf3Collections
        : featuredCollections,
      randomImageIndex: randomNumberFromBetweenXandY(1, 5),
    },
  };
};
