import { cx } from '@emotion/css';
import {
  Box,
  colors,
  FlexLayout,
  gradients,
  HeadingText,
  HeadingTextPropTypes,
  layoutHelpers,
  measurements,
  OuterSpace,
  SimpleText,
} from '@imtbl/design-system';
import { OfferLaunchBanner } from 'components/CollectionsListingPage/shared/OffersLaunchBanner.component';
import { FeatureFlag } from 'components/FeatureFlag';
import { SafeHydrate } from 'components/SafeHydrate.component';
import { FEATURE_FLAG } from 'config/feature-flags';
import { MarketplaceApiCollection } from 'marketplace-api/types';
import Image from 'next/image';
import { useCallback } from 'react';
import { RELATIVE_FEATURED_COLLECTION_ASSET_GRID_ITEM_SIZE } from 'utils/constants';

import { useURLSearchParams } from '../../hooks/useURLSearchParams.hook';
import {
  ButtonEventName,
  createButtonEvent,
  sendAnalytics,
} from '../../libs/analytics';
import { ClickableGridCollectionItem } from '../CollectionsListingPage/shared/ClickableGridCollectionItem.component';
import { SearchControl } from '../SearchControl';
import { CollectionsSearchResults } from './CollectionsSearchResults.component';
import * as styles from './Homepage.styles';
import { InfiniteCollectionsByVolumeGrid } from './InfiniteCollectionsByVolumeGrid.component';

const HOMEPAGE_HEADING_TEXT_PROPS: HeadingTextPropTypes = {
  fontSize: 'large',
  fillColor: colors.light[100],
  fontWeight: 'bold',
  textAlign: 'left',
};

export const Homepage = ({
  featuredCollections,
  randomImageIndex,
}: {
  featuredCollections: MarketplaceApiCollection[];
  randomImageIndex: number;
}) => {
  const searchParams = useURLSearchParams();
  const keywordSearch = searchParams.getKeywordSearch();
  const showCollectionsSearchResults = Boolean(keywordSearch);
  const showFeaturedItems =
    Boolean(featuredCollections?.length) && !showCollectionsSearchResults;
  const featuredCollectionClickHandler = useCallback(
    ({ address, name }: { address: string; name: string }) => {
      sendAnalytics(
        createButtonEvent(ButtonEventName.marketViewFeaturedCollectionPressed, {
          collectionId: address,
          collectionName: name,
        }),
      );
    },
    [],
  );

  return (
    <FlexLayout
      testId="homepageContainer"
      flexGrow={1}
      alignItems="center"
      flexDirection="column"
      backgroundGradient={gradients.bg.simple('top')}
    >
      <FlexLayout
        flexDirection="column"
        testId="homepageContainer__heroContainer"
        alignItems="center"
        flexGrow={1}
        className={styles.HeroContainer}
      >
        {showFeaturedItems && (
          <div
            className={cx(
              styles.BackgroundImageWrapper,
              styles.BackgroundImageWrapper___isDesktop,
            )}
            data-testid="heroBgImageContainer"
          >
            <Image
              src={`/images/illustration--homepage-bg-${randomImageIndex}.webp`}
              alt="blurred background game imagery"
              layout="fill"
              objectFit="cover"
              objectPosition="bottom center"
              quality={85}
              data-testid="heroBgImageContainer__heroBgImage"
              priority
            />
          </div>
        )}

        <Box
          className={styles.GradientSkrim}
          testId="homepageContainer__heroContainer__gradientSkrim"
        />

        <FlexLayout
          flexDirection="column"
          testId="homepageContainer__heroContainer__innerContainer"
          alignItems="center"
          maxWidth="1172px"
          width="100%"
          padding={layoutHelpers.gridUnits(3)}
          paddingTop="52px"
          paddingBottom="40px"
          className={styles.InnerContainer}
          responsiveProperties={[
            { breakpoint: 'small', paddingTop: '74px', paddingBottom: '67px' },
          ]}
        >
          {showFeaturedItems && (
            <HeadingText
              fontSize="3x-large"
              fillColor={colors.light[100]}
              fontWeight="bold"
              testId="homepageValuePropHeading"
            >
              Discover a world of gaming NFTs
            </HeadingText>
          )}

          <OuterSpace
            top={measurements.SpacingTeeShirtAmounts['x-large']}
            responsiveSpacing={[
              {
                breakpoint: 'small',
                top: measurements.SpacingTeeShirtAmounts['x-large'],
              },
            ]}
          >
            <FlexLayout
              flexDirection="column"
              testId="searchAndSortRow"
              alignItems="flex-start"
              className={styles.SearchControlContainer}
            >
              <SearchControl
                testId="collectionSearch"
                className={styles.SearchControlContainer_searchControl}
                searchBoxPlaceholder="Search collections"
              />
            </FlexLayout>
          </OuterSpace>

          {showCollectionsSearchResults && <CollectionsSearchResults />}

          {showFeaturedItems && (
            <>
              <OuterSpace
                top={measurements.SpacingTeeShirtAmounts['2x-large']}
                bottom={measurements.SpacingTeeShirtAmounts['2x-large']}
                responsiveSpacing={[
                  {
                    breakpoint: 'small',
                    bottom: measurements.SpacingTeeShirtAmounts['x-large'],
                    top: measurements.SpacingTeeShirtAmounts['4x-large'],
                  },
                ]}
              >
                <HeadingText
                  {...HOMEPAGE_HEADING_TEXT_PROPS}
                  className={styles.Heading}
                  testId="featuredGamesHeading"
                >
                  Featured game collection
                  <span
                    className={cx(
                      styles.Heading_sText,
                      styles.Heading_sText___isDesktop,
                    )}
                    data-testid="featuredGamesHeading__sText"
                  >
                    s
                  </span>
                </HeadingText>
              </OuterSpace>

              <FlexLayout
                testId="featuredCollections"
                columnGap={layoutHelpers.gridUnits(3)}
                width="100%"
                responsiveProperties={[
                  {
                    breakpoint: 'medium',
                    columnGap: layoutHelpers.gridUnits(10),
                  },
                ]}
              >
                {featuredCollections.map(collection => (
                  <ClickableGridCollectionItem
                    leftAlignCollectionName
                    relativeImageSizeInLayout={
                      RELATIVE_FEATURED_COLLECTION_ASSET_GRID_ITEM_SIZE
                    }
                    testId="featuredCollections__collection"
                    collection={collection}
                    key={collection.address}
                    className={cx(
                      styles.CollectionItem,
                      styles.CollectionItem___isDesktop,
                    )}
                    onClick={() => featuredCollectionClickHandler(collection)}
                  />
                ))}
              </FlexLayout>
            </>
          )}
        </FlexLayout>
      </FlexLayout>

      <FeatureFlag name={FEATURE_FLAG.ENABLE_OFFERS_BANNER}>
        <OfferLaunchBanner />
      </FeatureFlag>

      {!showCollectionsSearchResults && (
        <FlexLayout
          flexDirection="column"
          testId="homepageContainer__trendingCollectionsContainer"
          alignItems="center"
          maxWidth="1172px"
          width="100%"
          padding={layoutHelpers.gridUnits(3)}
          paddingTop={layoutHelpers.gridUnits(5)}
          paddingBottom={layoutHelpers.gridUnits(5)}
          responsiveProperties={[
            {
              breakpoint: 'small',
              paddingTop: layoutHelpers.gridUnits(16),
              paddingBottom: layoutHelpers.gridUnits(8),
            },
          ]}
        >
          <OuterSpace
            bottom={measurements.SpacingTeeShirtAmounts['2x-large']}
            responsiveSpacing={[
              {
                breakpoint: 'small',
                bottom: measurements.SpacingTeeShirtAmounts['3x-large'],
              },
            ]}
          >
            <HeadingText
              {...HOMEPAGE_HEADING_TEXT_PROPS}
              className={styles.Heading}
              testId="topTradingCollectionsHeading"
            >
              Top trading collections{' '}
              <SimpleText fontWeight="bold">(PAST 24 HOURS)</SimpleText>
            </HeadingText>
          </OuterSpace>

          <SafeHydrate>
            <InfiniteCollectionsByVolumeGrid testId="homepageContainer__trendingCollectionsContainer__trendingCollections" />
          </SafeHydrate>
        </FlexLayout>
      )}
    </FlexLayout>
  );
};
