import { css, cx } from '@emotion/css';
import {
  AssetListingGrid,
  BaseComponentPropTypes,
  breakpoints,
  colors,
  InfiniteScrollView,
  layoutHelpers,
  RectangularLoader,
  SimpleText,
  useResizeObserver,
} from '@imtbl/design-system';
import {
  prettyFormatStat,
  prettyFormatWithUSD,
} from 'components/BrowsePage/BrowsePageStatisticsGrid/utils';
import { NextLink } from 'components/DsConnectorButtons';
import { ImxHorizontalAssetTile } from 'components/ImxHorizontalAssetTile';
import { useClientSide } from 'hooks/useClientSide';
import { useCollections } from 'marketplace-api/collections';
import ROUTES from 'utils/router';

export function InfiniteCollectionsByVolumeGrid({
  testId,
  className,
}: BaseComponentPropTypes) {
  const { collections, pagesCount, setPagesCount, haveRemaining } =
    useCollections({ pageSize: 10, sortBy: 'volume_24h', direction: 'desc' });
  const { isClientSide } = useClientSide();
  const { width } = useResizeObserver({
    current: isClientSide ? document.body : null,
  });

  if (!collections) {
    return <RectangularLoader />;
  }

  return (
    <InfiniteScrollView
      childrenRemaining={haveRemaining}
      holdForFirstPage={collections.length < 1}
      loadMoreItems={() => setPagesCount(pagesCount + 1)}
      testId={testId}
    >
      <AssetListingGrid
        assetItemMinWidth={
          width <= parseInt(breakpoints.breakpointSizeMapping['2x-small'])
            ? '100%'
            : '370px'
        }
        rowGap={layoutHelpers.gridUnits(2)}
        columnGap={layoutHelpers.gridUnits(7)}
        testId={`${testId}__grid`}
        className={cx(
          css`
            padding: 0 0 ${layoutHelpers.gridUnits(6)};
          `,
          className,
        )}
      >
        {collections?.map(collection => {
          return (
            <NextLink
              key={collection.address}
              href={ROUTES.collectionPath({ collectionId: collection.address })}
              testId={`${testId}__grid__collection`}
            >
              <ImxHorizontalAssetTile
                testId={`${testId}__grid__collection__tile`}
                primaryText={collection.name}
                secondaryText={
                  collection?.stats?.volume_24h && (
                    <>
                      <SimpleText
                        fillColor={colors.light[300]}
                        fontWeight="bold"
                        fontSize="tag"
                      >
                        Volume
                      </SimpleText>{' '}
                      <SimpleText fillColor={colors.light[300]} fontSize="tag">
                        {prettyFormatWithUSD(
                          prettyFormatStat(
                            collection.stats.volume_24h.conversion_rollups.USD
                              .value,
                          ),
                        )}
                      </SimpleText>
                    </>
                  )
                }
                assetImage={collection.collection_image_url}
                className={css`
                  background: ${colors.bg[900]};
                `}
              />
            </NextLink>
          );
        })}
      </AssetListingGrid>
    </InfiniteScrollView>
  );
}
