import { css, cx } from '@emotion/css';
import {
  AssetListingGrid,
  BaseComponentPropTypes,
  layoutHelpers,
} from '@imtbl/design-system';
import { ReactNode } from 'react';
import {
  RESPONSIVE_ASSET_GRID_PROPS,
  SINGLE_COLUMN_GUTTER,
} from 'utils/constants';

export type StyledAssetListingGridProps = BaseComponentPropTypes & {
  children: ReactNode;
};

export const StyledAssetListingGrid = ({
  children,
  className,
  testId,
}: StyledAssetListingGridProps) => (
  <AssetListingGrid
    {...RESPONSIVE_ASSET_GRID_PROPS}
    rowGap={layoutHelpers.gridUnits(6)}
    columnGap={SINGLE_COLUMN_GUTTER}
    testId={testId}
    className={cx(
      css`
        padding: 0 ${SINGLE_COLUMN_GUTTER} ${layoutHelpers.gridUnits(6)};
      `,
      className,
    )}
  >
    {children}
  </AssetListingGrid>
);
