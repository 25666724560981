import { FloorPriceStat } from 'marketplace-api/types';

export function prettyFormatWithUSD(value: string) {
  const valueNum = Number(value);

  if (valueNum > 0 && valueNum <= 0.01) {
    return '<$0.01 USD';
  }

  return `$${value} USD`;
}

export function prettyFormatStat(stat: string) {
  const statNum = Number(stat);

  if (statNum === 0) {
    return '0';
  }

  if (statNum < 0.01) {
    return '0.01';
  }

  if (statNum < 1000) {
    return `${Number(statNum.toFixed(2))}`;
  }
  if (statNum < 1000000) {
    return `${Number((statNum / 1000).toFixed(1))}K`;
  }
  return `${Number((statNum / 1000000).toFixed(1))}M`;
}

export function getFloorPriceBreakdown(floorPrice: FloorPriceStat) {
  const { tokens } = floorPrice;
  const tokenNames = Object.keys(tokens);

  const breakdown: {
    tokenName: string;
    value: string;
    usdValue: string;
  }[] = [];

  for (const tokenName of tokenNames) {
    const token = tokens[tokenName];
    const { value } = token;
    const { value: usdValue } = token.conversions?.USD;
    breakdown.push({
      tokenName,
      value,
      usdValue,
    });
  }

  return breakdown;
}

export function getLowestFloorPriceInUSD(floorPrice: FloorPriceStat) {
  const { tokens } = floorPrice;
  const tokenNames = Object.keys(tokens);

  let lowestFloorPriceAcrossCurrencies = Infinity;
  let lowestUSDValueAcrossCurrencies = '';

  for (const tokenName of tokenNames) {
    const token = tokens[tokenName];
    const { value } = token;
    const { value: usdValue } = token.conversions?.USD;
    const floorPrice = Number(value);
    if (floorPrice < lowestFloorPriceAcrossCurrencies) {
      lowestFloorPriceAcrossCurrencies = floorPrice;
      lowestUSDValueAcrossCurrencies = usdValue;
    }
  }

  return lowestUSDValueAcrossCurrencies;
}
