import { css } from '@emotion/css';
import { layoutHelpers } from '@imtbl/design-system';

export const SectionHeading = css`
  padding-left: ${layoutHelpers.gridUnits(5)};
  padding-right: ${layoutHelpers.gridUnits(5)};
  margin-bottom: ${layoutHelpers.gridUnits(6)};

  &::before {
    left: ${layoutHelpers.gridUnits(5)};
  }
`;

export const ImxCollectionGrid = css`
  padding: 0 ${layoutHelpers.gridUnits(5)} ${layoutHelpers.gridUnits(6)};
  min-height: 200px;
`;

export const SearchbarContainer = css`
  margin: ${layoutHelpers.gridUnits(6)} ${layoutHelpers.gridUnits(5)}
    ${layoutHelpers.gridUnits(6)};
`;
