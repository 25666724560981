import { css } from '@emotion/css';
import { colorHelpers, colors, styleHelpers } from '@imtbl/design-system';

export const Searchbar___isMobile = css`
  top: var(--measurements_standardHeaderHeight);
  z-index: 2;
  padding: calc(var(--measurements_gridUnit) * 6) 0;
  background: rgba(${colorHelpers.hexToCssRgbString(colors.bg[800])}, 0.8);
  backdrop-filter: blur(10px);
`;

export const Searchbar___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery('small', `position: sticky;`);

export const Searchbar___isRecentlySoldActive = css`
  padding-bottom: calc(var(--measurements_gridUnit) * 3);
`;
