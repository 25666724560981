import {
  BaseComponentPropTypes,
  InfiniteScrollView,
  RectangularLoader,
} from '@imtbl/design-system';
import { useCollections } from 'marketplace-api/collections';

// import dynamic from 'next/dynamic';
import { useClientSide } from '../../../hooks/useClientSide';
import { ClickableGridCollectionItem } from './ClickableGridCollectionItem.component';
import { StyledAssetListingGrid } from './StyledAssetListingGrid.component';

export function InfiniteCollectionsGrid({
  testId,
  leftAlignCollectionName,
}: BaseComponentPropTypes & { leftAlignCollectionName?: boolean }) {
  const { isClientSide } = useClientSide();
  const { collections, pagesCount, setPagesCount, haveRemaining } =
    useCollections();

  return isClientSide && collections ? (
    <InfiniteScrollView
      childrenRemaining={haveRemaining}
      holdForFirstPage={collections.length < 1}
      loadMoreItems={() => setPagesCount(pagesCount + 1)}
      testId={testId}
    >
      <StyledAssetListingGrid testId={`${testId}__grid`}>
        {collections?.map(collection => {
          return (
            <ClickableGridCollectionItem
              collection={collection}
              key={collection.address}
              testId={`${testId}__grid__collection_${collection.address}`}
              leftAlignCollectionName={leftAlignCollectionName}
            />
          );
        })}
      </StyledAssetListingGrid>
    </InfiniteScrollView>
  ) : (
    <RectangularLoader />
  );
}
