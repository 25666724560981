import {
  colors,
  FlexLayout,
  HeadingText,
  layoutHelpers,
  measurements,
  OuterSpace,
  ParagraphText,
} from '@imtbl/design-system';
import { ReactNode } from 'react';

export function NoAssets({
  testId,
  children,
}: {
  testId?: string;
  children?: ReactNode;
}) {
  return (
    <FlexLayout
      testId={testId}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      paddingLeft={layoutHelpers.gridUnits(6)}
      paddingRight={layoutHelpers.gridUnits(6)}
    >
      <HeadingText fontSize="large" fillColor={colors.light[100]}>
        No items found
      </HeadingText>
      <OuterSpace
        top={measurements.SpacingTeeShirtAmounts['x-large']}
        bottom={measurements.SpacingTeeShirtAmounts.large}
      >
        <ParagraphText
          fontSize="normal"
          fillColor={colors.light[300]}
          textAlign="center"
        >
          Looks like there&apos;s nothing here.
        </ParagraphText>
      </OuterSpace>

      {children}
    </FlexLayout>
  );
}
