import { isStorageValid } from 'libs/analytics';
import { LocalStorageKey } from 'types';

export function getBuildOnImxToastSeenFromLocalStorage() {
  if (!isStorageValid(localStorage)) {
    return null;
  }

  return localStorage.getItem(LocalStorageKey.SEEN_BUILD_ON_IMMUTABLE_TOAST);
}

export function setBuildOnImxToastSeen() {
  if (!isStorageValid(sessionStorage)) {
    return;
  }

  localStorage.setItem(LocalStorageKey.SEEN_BUILD_ON_IMMUTABLE_TOAST, 'true');
}
