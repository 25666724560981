import { css, cx } from '@emotion/css';
import {
  BaseComponentPropTypes,
  colors,
  ImxCollectionTile,
} from '@imtbl/design-system';
import { NextLink } from 'components/DsConnectorButtons';
import React from 'react';
import {
  IMX_RESIZER_SERVICE_ENV_URL,
  RELATIVE_COLLECTION_ASSET_GRID_ITEM_SIZE,
} from 'utils/constants';
import ROUTES from 'utils/router';

import { MarketplaceApiCollection } from '../../../marketplace-api/types';

export const ClickableGridCollectionItem = ({
  collection,
  testId,
  onClick,
  className,
  leftAlignCollectionName,
  relativeImageSizeInLayout = RELATIVE_COLLECTION_ASSET_GRID_ITEM_SIZE,
}: {
  collection: MarketplaceApiCollection;
  onClick?: () => void;
  leftAlignCollectionName?: boolean;
  relativeImageSizeInLayout?: string;
} & BaseComponentPropTypes) => (
  <NextLink
    href={ROUTES.collectionPath({ collectionId: collection.address })}
    className={cx(
      css`
        display: flex;
        height: 100%;
      `,
      className,
    )}
    testId={testId}
    onClick={onClick}
  >
    <ImxCollectionTile
      testId={`${testId}__collectionTile`}
      collectionName={collection.name || ''}
      collectionImageUrl={
        collection.collection_image_url || collection.icon_url || undefined
      }
      relativeImageSizeInLayout={relativeImageSizeInLayout}
      imageResizeServiceUrl={IMX_RESIZER_SERVICE_ENV_URL}
      leftAlignName={leftAlignCollectionName}
      className={css`
        background: ${colors.dark[700]};
      `}
    />
  </NextLink>
);
