import { css, cx } from '@emotion/css';
import {
  addToast,
  breakpoints,
  colors,
  FlexLayout,
  layoutHelpers,
  measurements,
  OuterSpace,
  ParagraphText,
  SectionHeading,
  SimpleText,
  StyledLink,
  ToastAlignment,
  ToastType,
  VerticalSpace,
} from '@imtbl/design-system';
import { NoAssets } from 'components/NoAssets';
import * as searchBoxStyles from 'components/SearchAndSortBar/SearchAndSortBar.styles';
import { SearchControl } from 'components/SearchControl';
import { useURLSearchParams } from 'hooks/useURLSearchParams.hook';
import {
  ButtonEventName,
  createButtonEvent,
  createPageEvent,
  PageEventName,
  sendAnalytics,
} from 'libs/analytics';
import { useCollections } from 'marketplace-api/collections';
import { useEffect, useState } from 'react';
import {
  getBuildOnImxToastSeenFromLocalStorage,
  setBuildOnImxToastSeen,
} from 'utils/localStorage';

import { FEATURE_FLAG } from '../../../config/feature-flags';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag.hook';
import { MarketplaceApiCollection } from '../../../marketplace-api/types';
import { FeatureFlag } from '../../FeatureFlag';
import { ClickableGridCollectionItem } from '../shared/ClickableGridCollectionItem.component';
import { InfiniteCollectionsGrid } from '../shared/InfiniteCollectionsGrid.component';
import { StyledAssetListingGrid } from '../shared/StyledAssetListingGrid.component';
import { TokenGiveAwayBanner } from '../shared/TokenGiveAwayBanner.component';
import * as styles from './CollectionsPageMainContent.style';

export function CollectionsPageMainContent({
  featuredCollections = [],
}: {
  featuredCollections: MarketplaceApiCollection[];
}) {
  const [searchDisplayEnabled, toggleSearchDisplay] = useState(false);
  const searchParams = useURLSearchParams();
  const keywordSearch = searchParams.getKeywordSearch();
  const showFakeSearchBar = useFeatureFlag<boolean>(
    FEATURE_FLAG.ENABLE_HOMEPAGE_FAKE_SEARCH_BAR,
  );
  const featuredCollectionsFlagIsEnabled = useFeatureFlag<boolean>(
    FEATURE_FLAG.ENABLE_TRACKING_IN_AMPLITUDE_OF_FEATURED_COLLECTIONS,
  );

  const { collections } = useCollections();
  useEffect(() => toggleSearchDisplay(!!keywordSearch), [keywordSearch]);
  // @NOTE: per https://immutable.atlassian.net/browse/MP-737 -
  // display a toast just a single time for all users - advertising to
  // "build on Immutable"
  useEffect(() => {
    const previouslyNotSeenBuildToastMsg =
      !getBuildOnImxToastSeenFromLocalStorage();

    if (previouslyNotSeenBuildToastMsg) {
      setBuildOnImxToastSeen();
      addToast({
        title: 'Build on Immutable',
        type: ToastType.DEFAULT,
        alignment: ToastAlignment.TOP,
        dismissable: true,
        toastContent: (
          <ParagraphText testId="buildOnImxToast" fontSize="tag">
            The Immutable Marketplace is an example of what can be built with
            Immutable -{' '}
            <StyledLink
              fontWeight="bold"
              href="https://docs.x.immutable.com/docs"
              anchorDomProps={{ target: '_blank' }}
              fontSize="tag"
            >
              start building your own experiences today
            </StyledLink>
            !
          </ParagraphText>
        ),
        onToastClose: () => setBuildOnImxToastSeen(),
      });
    }
  }, []);

  useEffect(() => {
    if (featuredCollectionsFlagIsEnabled) {
      sendAnalytics(createPageEvent(PageEventName.marketCollectionsPageOpened));
    }
  }, [featuredCollectionsFlagIsEnabled]);

  const featuredCollectionClickHandler = ({
    address,
    name,
  }: {
    address: string;
    name: string;
  }) => {
    if (featuredCollectionsFlagIsEnabled) {
      sendAnalytics(
        createButtonEvent(ButtonEventName.marketViewFeaturedCollectionPressed, {
          collectionId: address,
          collectionName: name,
        }),
      );
    }
  };

  const showNoAssetsGui = !collections?.length && searchDisplayEnabled;
  const showFeaturedItems =
    !searchDisplayEnabled && !!featuredCollections?.length && !showNoAssetsGui;

  return (
    <FlexLayout flexDirection="column" flexGrow={1}>
      <FeatureFlag name={FEATURE_FLAG.ENABLE_TRADE_AND_EARN_BANNER}>
        <TokenGiveAwayBanner />
      </FeatureFlag>

      <FlexLayout
        flexDirection="column"
        flexGrow={1}
        role="main"
        marginLeft="auto"
        marginRight="auto"
        marginTop={
          showFakeSearchBar
            ? layoutHelpers.gridUnits(2)
            : layoutHelpers.gridUnits(4)
        }
        paddingBottom={layoutHelpers.gridUnits(8)}
        width="100%"
        maxWidth={breakpoints.breakpointSizeMapping.large}
      >
        <FlexLayout
          flexDirection="column"
          testId="searchAndSortRow"
          justifyContent="space-between"
          className={cx(
            searchBoxStyles.Searchbar___isMobile,
            searchBoxStyles.Searchbar___isDesktop,
            css`
              position: sticky;
            `,
          )}
        >
          <SearchControl
            testId="collections"
            className={css`
              margin-right: 0;
            `}
            searchBoxPlaceholder="Search collections"
          />

          {searchDisplayEnabled && (
            <VerticalSpace top="x-large" testId="searchResultsCopy">
              <SimpleText fillColor={colors.white}>
                Collection results for{' '}
                <SimpleText fontWeight="bold">
                  &quot;{keywordSearch}&quot;
                </SimpleText>
              </SimpleText>
            </VerticalSpace>
          )}
        </FlexLayout>

        {showNoAssetsGui && <NoAssets testId="noCollectionsMessaging" />}

        {showFeaturedItems && (
          <>
            <OuterSpace top={measurements.SpacingTeeShirtAmounts.medium}>
              <SectionHeading
                semanticHeadingIndex={3}
                fontSize="2x-large"
                testId="collectionPageSectionHeading---featured"
                className={styles.SectionHeading}
              >
                Featured
              </SectionHeading>
            </OuterSpace>

            <StyledAssetListingGrid testId="collectionPageListingGrid--featured">
              {featuredCollections.map(featuredCollection => {
                return featuredCollection ? (
                  <ClickableGridCollectionItem
                    collection={featuredCollection}
                    key={`${featuredCollection.address}--featured`}
                    testId={`collectionPageListingGrid--featured__collection_${featuredCollection.address}`}
                    onClick={() =>
                      featuredCollectionClickHandler(featuredCollection)
                    }
                  />
                ) : null;
              })}
            </StyledAssetListingGrid>
          </>
        )}

        {!searchDisplayEnabled && (
          <OuterSpace top={measurements.SpacingTeeShirtAmounts['x-large']}>
            <SectionHeading
              semanticHeadingIndex={3}
              fontSize="2x-large"
              testId="collectionPageSectionHeading---all"
              className={styles.SectionHeading}
            >
              All Collections
            </SectionHeading>
          </OuterSpace>
        )}

        {/* @NOTE: Render out a listing of all collections: */}
        {!showNoAssetsGui && (
          <InfiniteCollectionsGrid testId="collectionPageInfiniteListing" />
        )}
      </FlexLayout>
    </FlexLayout>
  );
}
