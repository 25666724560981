import { cx } from '@emotion/css';
import { BaseComponentPropTypes, SearchBox } from '@imtbl/design-system';
import { useURLSearchParams } from 'hooks/useURLSearchParams.hook';
import { scrollToTop } from 'utils/scrollToTop';

import * as styles from './SearchControl.styles';

export type SearchControlProps = BaseComponentPropTypes & {
  searchBoxPlaceholder?: string;
};

export function SearchControl({
  className,
  testId,
  searchBoxPlaceholder,
}: SearchControlProps) {
  const searchParams = useURLSearchParams();

  const onSearch = (newValue: string) => {
    if (!newValue) {
      searchParams.delete(['keywordSearch']);
    } else if (newValue !== searchParams.getKeywordSearch()) {
      searchParams.setKeywordSearch(newValue);
    }
    scrollToTop();
  };

  return (
    <SearchBox
      testId={`searchBox--${testId}`}
      labelText={searchBoxPlaceholder || ''}
      valueOveride={searchParams.getKeywordSearch() || ''}
      className={cx(
        styles.SearchControl___isMobile,
        styles.SearchControl___isDesktop,
        className,
      )}
      onSearch={onSearch}
    />
  );
}

export default SearchControl;
