import { css } from '@emotion/css';
import { styleHelpers } from '@imtbl/design-system';

export const SearchControl___isMobile = css`
  width: 100%;
`;

export const SearchControl___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'small',
    `
  width: auto;
  flex-grow: 1;
`,
  );
